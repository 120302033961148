@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: 'Open Sans', sans-serif;
}

h1, h2, h3, h4, h5 {
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
  background: #ffffff;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
}


@media (min-width: 1200px) {
  .center {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #510077;
  border-color: #510077 transparent #510077 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  display: grid;
  place-items: center;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

